import React from 'react';
import './CallToAction.scss';

// eslint-disable-next-line react/prop-types
const CallToAction = ({ onButtonClick }) => {
  return (
    <div className="cta-container">
      <h2 className="cta-title">Start Making an Impact Today</h2>
      <p className="cta-description">
        Sign up now to join Experts Circle and begin transforming opportunities for your connections.
      </p>
      <div className="cta-buttons">
        <a onClick={onButtonClick} className="cta-button primary">Join Now</a>
      </div>    
      </div>
  );
};

export default CallToAction;